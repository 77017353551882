<template>
    <v-card elevation="0" class="mx-auto text-center" width="100%">
        <v-card elevation="0" class="mt-12">
            <h1 class="justify-center">Scan Cart Barcode</h1>
            <v-icon color="secondary lighten-1" size="64px">fa-barcode</v-icon>
        </v-card>
        <v-alert
            v-show="error != ''"
            color="secondary"
            border="left"
            colored-border
            type="error"
            elevation="2"
            class="mt-6"
        >
            {{ error }}
        </v-alert>

        <ScannableInput
            :active="true"
            :debug="false"
            @scan="scan"
        ></ScannableInput>
    </v-card>
</template>

<script>
import * as data from '@/data/index';
import ScannableInput from '@/components/ScannableInput';

export default {
    name: 'Scan',
    data() {
        return {
            error: ''
        };
    },
    components: {
        ScannableInput
    },
    methods: {
        scan(response) {
            this.error = '';
            const patternToMatchNumberOnly = /^\d+$/;
            if (patternToMatchNumberOnly.test(response.input) === false) {
                this.error = 'Only numeric value is allowed for the cart-id.';
                return;
            }
            let self = this;
            this.$root.$dialogLoader.show('Finding the order…', {});
            data.verifications.getByCart(response.input).then(
                querySnapshot => {
                    if (querySnapshot.size) {
                        let verifications = querySnapshot.docs.map(doc =>
                            doc.data()
                        );
                        let verification = verifications[0];
                        verification.order.get().then(snap => {
                            let order = snap.data();
                            self.showCustomerServiceNote(order);
                        });
                    } else {
                        this.error =
                            "We can't find an order matching the barcode.";
                    }
                    self.$root.$dialogLoader.hide();
                },
                error => {
                    console.log(error);
                    self.$root.$dialogLoader.hide();
                }
            );
        },

        async showCustomerServiceNote(order) {
            if (
                !data.orders.shouldOrderShowCustomerServiceNote(order.order.id)
            ) {
                this.moveForward(order);
                return false;
            }

            let serviceNote = '';
            if (order.customer_service_note) {
                serviceNote = order.customer_service_note;
            }

            let customerNotes = '';
            const snapshot = await data.customerNotes.getCustomerNotes(
                order.order.customer_email
            );
            if (snapshot.exists && snapshot.data().notes !== '') {
                customerNotes = snapshot.data().notes;
            }

            if (serviceNote === '' && customerNotes === '') {
                this.moveForward(order);
                return false;
            }
            if (
                await this.$root.$orderCustomerServiceNote.show(
                    order.order.id,
                    serviceNote,
                    customerNotes
                )
            ) {
                this.moveForward(order);
            }
        },

        moveForward(order) {
            if (order.is_locked && order.is_locked === true) {
                this.$router.push({
                    name: 'Home',
                    params: {
                        alert_message: `Orders (${order.order.id}) is currently locked.`,
                        show_alert: true
                    }
                });
                return false;
            }
            if (order.internalStatus == 'needs-shipped') {
                this.$router.push({
                    name: 'OrderBarcodeScan',
                    params: { id: order.order.id }
                });
            } else {
                this.$router.push({
                    name: 'Pull',
                    params: { id: order.order.id }
                });
            }
        }
    }
};
</script>
